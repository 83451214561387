<template>
  <div>
    <img src="../../../assets/images/login/mail.png" width="80" alt="" />
    <h1 class="mt-3 mb-0">{{ $t("success") }} !</h1>
    <span
      >{{ $t("emailSend") }} {{ this.userEmail }}.
      {{ $t("pleaseCheckYourEmail") }}
    </span>
    <v-col cols="12">
      <general-button :loading="false" :message="$t('backToHome')" size="medium" secondary  :action="() => $router.push({ name: 'auth1.sign-in1' })"/>
    </v-col>
  </div>
</template>
<script>
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
export default {
  name: 'ConfirmMail1',
  components: { GeneralButton },
  props: {
    email: {
      type: String
    }
  },
  data () {
    return {
      userEmail: this.email
    };
  }
};
</script>
